<!-- Succeed Modal -->
<div class="succeedConfirmationModal" [ngClass]="{'show' : data.shouldShowSuccess === true}">
  <h1>{{ 'GeneralAdditional.Success' | translate }}</h1>
  <div class="form-element row">
    <div class="col text-center">
      <button class="btn btn-primary btn-block my-4" (click)="onLoginButtonClicked()">Login</button>
    </div>
  </div>
</div>


<!--Failed Modal -->
<div class="failedConfirmationModal" [ngClass]="{'show' : data.shouldShowSuccess === false}">
  <h1>Failed</h1>
  <div class="form-element row">
    <div class="col text-center">
      <button class="btn btn-primary btn-block my-4" (click)="onResendButtonClicked()">Resend Confirmation Email</button>
    </div>
  </div>
</div>
