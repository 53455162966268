import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ResponseCode } from 'src/app/enums/response-code';
import { FinishStateTypes } from 'src/app/enums/finish-state-types.enum';
import { OfflinePageTypes } from 'src/app/enums/offline-page-types';
import { MDBModalService, MDBModalRef } from 'ng-uikit-pro-standard';
import { BaseFinishComponent } from 'src/app/modules/user/modules/questions-framework/components/finish-pages/base-finish/base-finish.component';
import { QuestionStoreService } from '../../user-services/other-services/question-store.service';
import { BaseOfflineComponent } from 'src/app/modules/user/modules/questions-framework/components/offline-pages/base-offline/base-offline.component';

@Injectable()
export class StatusCodeHandlerInterceptor implements HttpInterceptor {

  modalRef: MDBModalRef;
  
  modalOptions = {
    backdrop: true,
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: '',
    containerClass: '',
    animated: true,
    data: {}
  }

  constructor(private modalService: MDBModalService, private questionStoreService: QuestionStoreService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((response: any) => {
      switch (response.status) {
       
        case ResponseCode.PersonalDetailsFinished: {
          this.modalOptions.data = { state: FinishStateTypes.GettingToKnowYou };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }
        case ResponseCode.IncomeRecoveriesFinished: {
          this.modalOptions.data = { state: FinishStateTypes.LetsTalkMoney };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }       
        case ResponseCode.StateTaxesFinished: {
          this.modalOptions.data = { state: FinishStateTypes.StateTaxes };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }
        case ResponseCode.MessageForResidencyFinishWithNonResident: {
          this.modalOptions.data = { state: FinishStateTypes.MessageForResidencyFinishWithNonResident };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }
        case ResponseCode.MessageForResidencyFinishWithEmigrant: {
          this.modalOptions.data = { state: FinishStateTypes.MessageForResidencyFinishWithEmigrant };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }
        case ResponseCode.ExpensesTypesFinished: {
          this.modalOptions.data = { state: FinishStateTypes.StateFinished };
          this.modalRef = this.modalService.show(BaseFinishComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldFetchAnswers$.next(true);
          });
          break;
        }
        case ResponseCode.MessageForResidencyFinishWithImmigrant: {
          this.modalOptions.data = { state: OfflinePageTypes.MessageForResidencyFinishWithImmigrant };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.FhsaOfflineStateEvent: {
          this.modalOptions.data = { state: OfflinePageTypes.FhsaStatusCode };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.MessageForResidencyFinishWithResident: {
          this.modalOptions.data = { state: OfflinePageTypes.MessageForResidencyFinishWithResident };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.Deceased: {
          this.modalOptions.data = { state: OfflinePageTypes.Deceased };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.M51Emigrant: {
          this.modalOptions.data = { state: OfflinePageTypes.M51Emigrant };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.OfflineStateEvent: {
          this.modalOptions.data = { state: OfflinePageTypes.SinNumber };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.Option2OfflineStateEvent: {
          this.modalOptions.data = { state: OfflinePageTypes.M11Options2 };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.ResidencyOfflineStateEvent: { 
          this.modalOptions.data = { state: OfflinePageTypes.ProvinceOfEmployment };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.NotSupportedVisa: {
          this.modalOptions.data = { state: OfflinePageTypes.TurbotaxSupportedVisas };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break; 
        }
        case ResponseCode.Resident: {
          this.modalOptions.data = { state: OfflinePageTypes.Resident };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.ResidentTurboTax: {
          this.modalOptions.data = { state: OfflinePageTypes.TurbotaxSupportedVisas };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.M12: {
          this.modalOptions.data = { state: OfflinePageTypes.M12 };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.M11: {
          this.modalOptions.data = { state: OfflinePageTypes.M11 };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.NoUsIncomeStatusCode: {
          this.modalOptions.data = { state: OfflinePageTypes.NoUsIncomeStatusCode };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        case ResponseCode.DifferentServiceTypeStatusCode: {
          this.modalOptions.data = { state: OfflinePageTypes.DifferentServiceTypeStatusCode };
          this.modalRef = this.modalService.show(BaseOfflineComponent, this.modalOptions);
          this.modalRef.content.close.subscribe( () => { 
            this.questionStoreService.shouldDisableSubmitButton$.next(false);
          });
          break;
        }
        default: { }
      }
    }));
  }
}
