import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';
import { TokenService } from 'src/app/services/shared-services/other-services/token/token.service';
import 'rxjs/add/operator/map';
import { EnvironmentStoreService } from 'src/app/services/shared-services/other-services/store/environment-store.service';
import { BaseUrlModel } from 'src/app/services/shared-services/other-services/store/models/base-url.model';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {
  shouldShowEditBtns: boolean = this.environmentStore.shouldShowEditBtns$.getValue();
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private tokenService: TokenService, 
    private environmentStore: EnvironmentStoreService) { }

  canLoad(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean {
    if(this.authService.isAuthenticated()) {
      this.router.navigate(['user']);

      return;
    }

    /**
     * BE - use in Dev/Local env only
     * Check url query params(it is wp login) => env server config
     */
    const dashboardBaseUrl = route.queryParams.dashboardBaseUrl;
    const dashboardBasePort = route.queryParams.dashboardBasePort;
    const dashboardTaxYear = route.queryParams.dashboardTaxYear;

    if(dashboardTaxYear !== undefined) {
      this.environmentStore.updateBeDashboardTaxYear(dashboardTaxYear)
    }

    if(dashboardBaseUrl !== undefined && dashboardBasePort !== undefined && dashboardBaseUrl !== '' && dashboardBasePort !== '') {
      let url = this.environmentStore.getBaseUrl();
      
      let updatedUrl = new BaseUrlModel();
      updatedUrl.protocol = url.protocol;
      updatedUrl.apiVersion = url.apiVersion;
      updatedUrl.url = dashboardBaseUrl;
      updatedUrl.port = dashboardBasePort;
  
      this.environmentStore.updateUrl(updatedUrl);
      this.environmentStore.updateBeDashboardBaseUrlAndPort(dashboardBaseUrl, dashboardBasePort);
    }

    /**
     * Use in Dev/Local env only
     */
    if (this.shouldShowEditBtns) {

      return true;
    }
    
  /**
   * Check user identity and Login page
   * 
   * If has a userToken in the url query params(it is wp login) => loading Angular application
   * Else => loading WP Home page
   */
    const userToken = route.queryParams.token;
    
    if( userToken !== undefined ) {
      this.tokenService.setToken(userToken);
      let theme = localStorage.getItem('theme');
      document.body.classList.add(theme);
      this.router.navigate(['user']);

      return true;
    }else {
      window.location.href = this.environmentStore.getLocalServerUrl();
      
      return false
    }
  }
}

