<a href="#" class="btn btn-secondary btn-create d-none d-lg-inline-block" (click)="onCreateAccountButtonClicked()">
  Create
  Account
</a>
<h2>Sign In to Sprintax Account</h2>
<p class="with">
  <a href="#"><img src="../assets/img/icon-fb.png" alt="sign in with facebook"></a>
  <a href="#"><img src="../assets/img/icon-tw.png" alt="sign in with twitter"></a>
</p>
<p>or use your email account</p>
<button type="button" aria-label="Close" class="mat-button mat-button-base btn--close d-xs-block d-lg-none"
  mat-dialog-close>
  <span aria-hidden="true">×</span>
</button>

<div class="form-content">
  <div *ngIf="accountService.hasError" class="form-group text-danger">
    {{accountService.errorMessage}}
  </div>

  <div *ngIf="accountService.successfullMessage" class="form-group text-success">
    {{accountService.successfullMessage}}
  </div>

  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" novalidate>
    <div class="md-form">
      <input mdbInput type="email" id="loginEmail" formControlName="email" class="form-control">
      <label for="loginEmail">{{ 'MyProfile.EmailLabel' | translate }}</label>
      <mdb-error class="form-field-error"
        *ngIf="formGroup.get('email').errors?.required && (formGroup.get('email').dirty || formGroup.get('email').touched)">
        Email is required
      </mdb-error>
      <mdb-error class="form-field-error"
        *ngIf="formGroup.get('email').errors?.pattern && (formGroup.get('email').dirty || formGroup.get('email').touched)">
        Please enter a valid email
      </mdb-error>
    </div>
    <div class="md-form">
      <input mdbInput type="password" formControlName="password" id="loginPassword" class="form-control">
      <label for="loginPassword">Password</label>
      <mdb-error class="form-field-error"
        *ngIf="formGroup.get('password').errors?.required && (formGroup.get('password').dirty || formGroup.get('password').touched)">
        Password is required
      </mdb-error>
    </div>
    <div class="form-field submit">
      <button class="btn btn-primary">
        Log
        in
      </button>
      <div class="mobile-view pt-2">
        <p class="d-flex justify-content-center align-items-center fw_500">or</p>
        <a href="#" class="btn btn-ghost btn-create text-uppercase" (click)="onCreateAccountButtonClicked()">
          Create
          Account
        </a>
      </div>
    </div>
  </form>
</div>
<p class="forgot"><a (click)="onForgottenPasswordButtonClicked()">Forgot password?</a></p>