import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { EnvironmentStoreService } from '../shared-services/other-services/store/environment-store.service';
import { HttpErrorsService } from '../shared-services/other-services/http-error/http-errors.service';

@Injectable()
export abstract class BaseHttpService {
  private _hasError: boolean;
  private _errorMessage: string;
  private _successfullMessage: string;

  constructor(private httpErrorsService: HttpErrorsService, public environmentStore: EnvironmentStoreService) {
    this._hasError = false;
    this._errorMessage = '';
    this._successfullMessage = '';
  }

  get hasError(): boolean {
    return this._hasError;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  get successfullMessage(): string {
    return this._successfullMessage;
  }

  setSuccessfullMessage(message: string): void {
    this._successfullMessage = message;
  }

  get headers(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
  }

  handleHttpError(httpErrorResponse: HttpErrorResponse, routerParam?: string | string[], id?: number): void {
    this._hasError = true;

    this.httpErrorsService.handleError(httpErrorResponse, (error) => this.setError(error), routerParam, id);
  }

  clearError(): void {
    this._errorMessage = '';
    this._successfullMessage = '';
    this._hasError = false;
  }

  getBaseUrl(): string {
    let url = this.environmentStore.getBaseUrl();
    return url.protocol + '://' + url.url + ':' + url.port + '/';
  }

  getSsoUrl(): string {
    return this.environmentStore.getSsoUrl();
  }

  getPaymentServerUrl(): string {
    let url = this.environmentStore.getBaseUrl();
    return url.protocol + '://' + url.paymentServerUrl + '/';
  }

  getDashboardGetBaseUrl(): string {
    let url = this.environmentStore.getDashboardGetBaseUrl();
    return url.protocol + '://' + url.url + ':' + url.port + '/';
  }

  getApiVersion(): number {
    let url = this.environmentStore.getBaseUrl();
    return url.apiVersion;
  }

  private setError(error: string | { [property: string]: string | string[] }): void {
    if (typeof error === 'string') {
      this._errorMessage = error;
    }
    else {
      for (let property in error) {
        if (typeof error[property] !== 'string' && Array.isArray(error[property])) {
          this._errorMessage += property + ' validation errors: ' + (error[property] as string[]).join('; ') + '\n';
        }
        else {
          this._errorMessage += error[property] + '\n';
        }
      }
    }
  }
}
