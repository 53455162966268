<div class="non-residency-offline">
    <div class="box">
        <div class="modal-header justify-content-center py-4">
            <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
                <i class="icon-check3 text-green mr-2"></i>{{ 'Messages.FinalScreenMessages.PersonalDetails.Success' | translate }}
            </p>
        </div>
        <div class="body">
            <h4>{{ 'Messages.FinalScreenMessages.PersonalDetails.title' | translate }}!</h4>
            <p>{{ 'Messages.FinalScreenMessages.PersonalDetails.p1' | translate }}
                {{taxYear}}, {{ 'Messages.FinalScreenMessages.PersonalDetails.p2' | translate }}!</p>
            <button (click)="submit()" class="btn btn-primary">{{ 'GeneralAdditional.Next' | translate }}</button>
        </div>
    </div>
</div>