<a href="" class="btn btn-secondary btn-login d-none d-lg-inline-block" (click)="onLoginButtonClicked()">Log in</a>
<h2>Create Sprintax Account</h2>
<p class="with">
  <a href="#"><img src="../assets/img/icon-fb.png" alt="sign in with facebook"></a> <a href="#">
    <img src="../assets/img/icon-tw.png" alt="sign in with twitter">
  </a>
</p>
<p>or use your email for registration</p>
<button type="button" aria-label="Close" class="mat-button mat-button-base btn--close d-xs-block d-lg-none"
  mat-dialog-close>
  <span aria-hidden="true">×</span>
</button>
<div class="form-content sign-up-container">
  <div *ngIf="accountService.hasError" class="alert-error mb-2 response-message p-2">
    {{accountService.errorMessage}}
  </div>
  <div *ngIf="accountService.successfullMessage" class="alert-success mb-2 response-message p-2">
    {{accountService.successfullMessage}}
  </div>
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" novalidate>
    <div class="form-group row">
      <div class="col-12 col-sm-6">
        <div class="md-form">
          <input mdbInput type="text" formControlName="firstName" class="form-control" id="firstName"
            autocomplete="off">
          <label for="firstName">{{ 'MyProfile.FirstNameLabel' | translate }}</label>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('firstName').errors?.required && (formGroup.get('firstName').dirty || formGroup.get('firstName').touched)">
            {{ 'MyProfile.FirstNameIsRequired' | translate }}
          </mdb-error>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('firstName').errors?.pattern && (formGroup.get('firstName').dirty || formGroup.get('firstName').touched)">
            Please enter a valid name
          </mdb-error>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="md-form">
          <input mdbInput type="text" formControlName="lastName" class="form-control" id="lastName" autocomplete="off">
          <label for="lastName">{{ 'MyProfile.LastNameLabel' | translate }}</label>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('lastName').errors?.required && (formGroup.get('lastName').dirty || formGroup.get('lastName').touched)">
            {{ 'MyProfile.LastNameIsRequired' | translate }}
          </mdb-error>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('lastName').errors?.pattern && (formGroup.get('lastName').dirty || formGroup.get('lastName').touched)">
            Please enter a valid name
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-md-7 col-lg-6">
        <div class="row">
          <div class="col-12 col-sm-5">
            <div class="md-form">
              <div class="md-form">
                <mdb-select [filterEnabled]="true" [options]="countries | sort:'label'" placeholder="Country"
                  formControlName="phoneCode">
                </mdb-select>
                <mdb-error class="form-field-error"
                  *ngIf="formGroup.get('phoneCode').errors?.required && (formGroup.get('phoneCode').dirty || formGroup.get('phoneCode').touched)">
                  Required field
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-7">
            <div class="md-form">
              <input mdbInput type="text" formControlName="phoneNumber" id="phoneNumber" class="form-control"
                autocomplete="off">
              <label for="phoneNumber">Phone</label>
              <mdb-error class="form-field-error"
                *ngIf="formGroup.get('phoneNumber').errors?.required && (formGroup.get('phoneNumber').dirty || formGroup.get('phoneNumber').touched)">
                Phone number is required
              </mdb-error>
              <mdb-error class="form-field-error"
                *ngIf="formGroup.get('phoneNumber').errors?.pattern && (formGroup.get('phoneNumber').dirty || formGroup.get('phoneNumber').touched)">
                Please enter a phone number
              </mdb-error>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-6">
        <div class="md-form">
          <input mdbInput type="email" formControlName="email" id="email" class="form-control" autocomplete="off">
          <label for="email">{{ 'MyProfile.EmailLabel' | translate }}</label>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('email').errors?.required && (formGroup.get('email').dirty || formGroup.get('email').touched)">
            Email is required
          </mdb-error>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('email').errors?.pattern && (formGroup.get('email').dirty || formGroup.get('email').touched)">
            Please enter a valid email
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-sm-6">
        <div class="md-form">
          <input mdbInput type="password" formControlName="password" id="password" class="form-control"
            autocomplete="off">
          <label for="password">Password</label>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('password').errors?.required && (formGroup.get('password').dirty || formGroup.get('password').touched)">
            Password is required
          </mdb-error>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('password').errors?.minlength && (formGroup.get('password').dirty || formGroup.get('password').touched)">
            Must be at least 4 characters long
          </mdb-error>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="md-form">
          <input mdbInput type="password" formControlName="passwordConfirmed" id="passwordConfirmed"
            class="form-control" autocomplete="off">
          <label for="passwordConfirmed">Confirm password</label>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('passwordConfirmed').errors?.required && (formGroup.get('passwordConfirmed').dirty || formGroup.get('passwordConfirmed').touched)">
            Password confirmation is required
          </mdb-error>
          <mdb-error class="form-field-error"
            *ngIf="formGroup.get('passwordConfirmed').errors?.compare && (formGroup.get('passwordConfirmed').dirty || formGroup.get('passwordConfirmed').touched)">
            Passwords mismatch
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 text-left mb-2">
        <mdb-checkbox [checked]="areTermsAccepted" (change)="areTermsAccepted=!areTermsAccepted"
          formControlName="termsAndConditions">
          I agree to the
          <a href="https://www.sprintax.com/terms-and-conditions.html" target="_blank" class="pl-1">
            Sprintax
            terms and conditions
          </a>
        </mdb-checkbox>
        <mdb-error class="form-checkbox-error"
          *ngIf="formGroup.get('termsAndConditions').errors?.required && (formGroup.get('termsAndConditions').dirty || formGroup.get('termsAndConditions').touched)">
          You have to agree with terms and conditions
        </mdb-error>
      </div>
      <div class="col-12 text-left mb-2">
        <mdb-checkbox [checked]="isUserAgreeToIRC" (change)="isUserAgreeToIRC=!isUserAgreeToIRC"
          formControlName="informationDisclosure">
          I agree to the
          <a href="https://www.sprintax.com/information-disclosure.html" target="_blank" class="pl-1">Information
            disclosure under IRC Section 7216</a>
        </mdb-checkbox>
        <mdb-error class="form-checkbox-error"
          *ngIf="formGroup.get('informationDisclosure').errors?.required && (formGroup.get('informationDisclosure').dirty || formGroup.get('informationDisclosure').touched)">
          You have to agree to continue
        </mdb-error>
      </div>
    </div>
    <div class="form-field submit">
      <button class="btn btn-primary">Create account</button>
      <div class="mobile-view pt-2">
        <p class="d-flex justify-content-center align-items-center fw_500">or</p>
        <a href="" class="btn btn-ghost btn-login text-uppercase" (click)="onLoginButtonClicked()">Log in</a>
      </div>
    </div>
  </form>
</div>