import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-m11-options-offline',
  templateUrl: './m11-options-offline.component.html',
  styleUrls: ['./m11-options-offline.component.scss']
})
export class M11OptionsOflineComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }

}
