import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';

import { DEFAULT_THEME, THEME_KEY } from './consts/consts';
import { TranslateService } from '@ngx-translate/core';
import { QuestionStoreService } from './services/user-services/other-services/question-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, public translate: TranslateService, public questionStoreService: QuestionStoreService) {
    translate.addLangs(['en-US', 'fr-CA']);
    translate.setDefaultLang('en-US');

    const browserLang = translate.getBrowserLang();

    if(localStorage.getItem('language') !== null) {
      translate.use(localStorage.getItem('language'))
    }else {
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en-US');
    }
  }

  ngOnInit(): void {
    let theme = '';
    if (this.authService.isAuthenticated()) {
      theme = localStorage.getItem(THEME_KEY);

      if (theme === null || theme === undefined || theme === '') {
        theme = DEFAULT_THEME;
      }
    }
    else {
      localStorage.removeItem(THEME_KEY);

      theme = DEFAULT_THEME;
    }
    document.body.classList.remove('dark', 'light');
    document.body.classList.add(theme);
  }
}
