<p class="font-weight-bold text-center">{{ 'DocsExchange.SelectDocumentType' | translate }}</p>
<!-- <mdb-select [options]="" placeholder="Document type"></mdb-select> -->
<div class="file-container mt_15" drag-and-drop (fileDropped)="onFileDropped($event)">
  <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />

  <i class="icon-drag icon"></i>
  <p class="mt_30 mb-0">Drag &amp; Drop a file or click the icon to selecta file</p>
  <!-- <p class="fs_13 mb-0 mt_15 text-center">Accepted file types: PDF, JPG, JPEG, GIF, PNG</p>
  <p class="fs_13 mb-0 text-center">Maximum file size: 4 MB</p> -->
</div>
<p *ngIf="shouldShowError" class="mt_10 mb-0 fs_12 text-red">{{ 'DocsExchange.MaximumFileSizeOrInvalidFileType' | translate }}.</p>
<div class="files-list">
  <div class="single-file" *ngIf="file !== null && file !== undefined">
    <i class="icon-forms mr_10"></i>

    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-file-progress [progress]="file?.progress"></app-file-progress>
    </div>

    <div class="delete" (click)="deleteFile(i)">
      <i class="ico ico-remove"></i>
    </div>
  </div>
</div>