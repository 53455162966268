import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-m-eleven',
  templateUrl: './m-eleven.component.html',
  styleUrls: ['./m-eleven.component.scss']
})
export class MElevenComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }

}
