import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';
import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';

import { ResetPasswordDialogComponent } from '../home-components/reset-password-dialog/reset-password-dialog.component';
import { EmailConfirmationDialogComponent } from '../home-components/email-confirmation-dialog/email-confirmation-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  searchResult;

  private subscription: Subscription

  constructor(
    private router: Router, 
    private authenticationService: AuthService, 
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, 
    private dialog: MatDialog, 
    private accountService: AccountService) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    if (this.authenticationService.isAuthenticated() && !this.authenticationService.isAdmin()) {
      this.router.navigate(['user', 'taxreturns']);
    }

    this.searchForm = this.generateFormGroup(); 
  }

  onSearchBtnClicked(): void {
    if (this.searchForm.valid) {
      let model = {
        'userId': this.searchForm.get('userId').value,
        'userEmail': this.searchForm.get('userEmail').value
      }

      this.accountService.getUsers(model)
        .subscribe((response) => {
          this.searchResult = response
        }, (error: HttpErrorResponse) => {
          this.accountService.handleHttpError(error);
        });
    }
    else {
      this.searchForm.markAllAsTouched();
    }
  }

  private generateFormGroup(): FormGroup {
    let userIdFormControl = this.formBuilder.control(null);
    let userEmailFormControl = this.formBuilder.control(null);

    return this.formBuilder.group({
      userId: userIdFormControl,
      userEmail: userEmailFormControl
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
