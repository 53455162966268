export enum OfflinePageTypes {
  NonResident = 0,
  Resident = 1,
  TurbotaxSupportedVisas = 2,
  TaxbackSupportedVisas = 3,
  PersonalDetails = 4,
  NotSupportedVisas = 5,
  M12 = 6,
  M11 = 18,
  M11Options2 = 19,
  NoUsIncomeStatusCode = 10,
  DifferentServiceTypeStatusCode = 11,
  FhsaStatusCode = 18,

  /* Canada TDS */
  SinNumber = 12,
  ProvinceOfEmployment = 13,
  MessageForResidencyFinishWithImmigrant = 14,
  MessageForResidencyFinishWithResident = 15,
  Deceased = 16,
  M51Emigrant = 17,
}
