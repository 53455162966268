import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminPanelComponent } from 'src/app/components/admin-panel/admin-panel.component';
import { PageNotFoundComponent } from '../../../components/page-not-found/page-not-found.component';
import { ServerErrorsComponent } from 'src/app/components/server-errors/server-errors.component';

import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: AdminPanelComponent, pathMatch: 'full' },
  { path: 'user', loadChildren: () => import( '../../../modules/user/user.module').then(m => m.UserModule), canLoad: [AuthGuard] },
  { path: 'server-error', component: ServerErrorsComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
      anchorScrolling: 'enabled' })
  ],
  providers: [
    AuthGuard
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
