import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-offset-your-expenses',
  templateUrl: './offset-your-expenses.component.html',
  styleUrls: ['./offset-your-expenses.component.scss']
})
export class OffsetYourExpensesComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
