<div class="residency-offline">
  <div class="box">
    <div class="modal-header justify-content-center py-4">
      <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
        <i class="icon-warning text-green mr-2"></i>{{ 'GeneralAdditional.CheckAgain' | translate }}
      </p>
    </div>
    <div class="body">
      <p> {{'Messages.Offline.SIN' | translate }}. </p>
      <button class="btn btn-primary" (click)="submit()">{{ 'GeneralAdditional.Back' | translate }}</button>
    </div>
  </div>
</div>