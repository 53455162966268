<ng-container [ngSwitch]="state">
  <app-residency-offline *ngSwitchCase="status.Resident" (action)="onActionEvent()"></app-residency-offline>
  <app-turbo-tax-offline *ngSwitchCase="status.TurbotaxSupportedVisas" (action)="onActionEvent()">
  </app-turbo-tax-offline>
  <app-taxback-offline *ngSwitchCase="status.TaxbackSupportedVisas" (action)="onActionEvent()"></app-taxback-offline>
  <app-residency-offline *ngSwitchCase="status.PersonalDetails" (action)="onActionEvent()"></app-residency-offline>
  <app-not-supported-visa-offline *ngSwitchCase="status.NotSupportedVisas" (action)="onActionEvent()">
  </app-not-supported-visa-offline>
  <app-married-filing *ngSwitchCase="status.M12" (action)="onActionEvent()"></app-married-filing>
  <app-m-eleven *ngSwitchCase="status.M11" (action)="onActionEvent()"></app-m-eleven>
  <app-m11-options-offline *ngSwitchCase="status.M11Options2" (action)="onActionEvent()"></app-m11-options-offline>
  <app-no-us-income *ngSwitchCase="status.NoUsIncomeStatusCode" (action)="onActionEvent()"></app-no-us-income>
  <app-different-service-type *ngSwitchCase="status.DifferentServiceTypeStatusCode" (action)="onActionEvent()"></app-different-service-type>
  
  <!-- Sprintax Canada -->
  <app-sin-number-offline *ngSwitchCase="status.SinNumber" (action)="onActionEvent()"></app-sin-number-offline>
  <app-province-of-employment-ofline *ngSwitchCase="status.ProvinceOfEmployment" (action)="onActionEvent()"></app-province-of-employment-ofline>
  <app-massachusetts-review-four *ngSwitchCase="status.MessageForResidencyFinishWithImmigrant" (action)="onActionEvent()"></app-massachusetts-review-four>
  <app-massachusetts-review-one *ngSwitchCase="status.MessageForResidencyFinishWithResident" (action)="onActionEvent()"></app-massachusetts-review-one>
  <app-offset-your-expenses *ngSwitchCase="status.Deceased" (action)="onActionEvent()"></app-offset-your-expenses>
  <app-emigrant-offline *ngSwitchCase="status.M51Emigrant" (action)="onActionEvent()"></app-emigrant-offline>
</ng-container>