<div class="residency-offline">
    <div class="box">
        <div class="modal-header justify-content-center py-4">
            <p class="heading d-flex align-items-center text-white m-0 font-weight-bold">
                <i class="icon-check3 text-green mr-2"></i>{{ 'GeneralAdditional.Success' | translate }}
            </p>
        </div>
        <div class="body">
            <p>{{ 'Messages.FinalScreenMessages.RecidentStatus.p1' | translate }} Non-resident {{ 'Messages.FinalScreenMessages.RecidentStatus.p2' | translate }}.</p>
            <button (click)="submit()" class="btn btn-primary">{{ 'GeneralAdditional.Next' | translate }}</button>
        </div>
    </div>
</div>
