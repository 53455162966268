import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-emigrant-offline',
  templateUrl: './emigrant-offline.component.html',
  styleUrls: ['./emigrant-offline.component.scss']
})
export class EmigrantOfflineComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
