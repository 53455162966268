import { Component, EventEmitter, Output } from '@angular/core';
import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';
import { FinalScrStoreService } from '../../final-screens/services/store/final-scr-store.service';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent {
  @Output() action: EventEmitter<void>;
  constructor(
    public finalScrStore: FinalScrStoreService,
    private questionStore: QuestionStoreService) { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.questionStore.setPrevComp('expenses');
    this.action.next();
  }
}
