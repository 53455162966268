<div class="residency-offline">
  <div class="box">
    <div class="body">
      <p>
        {{'Messages.Offline.M11' | translate }}:
        <a href=" https://www.taxback.com/en/canada/non-resident-tax-refund-application-b/"
          target="_blank">https://www.taxback.com/en/canada/non-resident-tax-refund-application-b/</a>
      </p>
      <button class="btn btn-primary" (click)="submit()">{{ 'DocsExchange.Close' | translate }}</button>
    </div>
  </div>
</div>