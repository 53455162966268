import { Injectable } from '@angular/core';
import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';

export enum TaxStatus {
  Balance = 0,
  Refund = 1,
  Owe = 2
}

@Injectable({
  providedIn: 'root'
})
export class FinalScrStoreService {
  calculatedProvinces = ['ON', 'AB', 'QC', 'BC'];
  calculatedProvinces2020 = ['ON', 'AB', 'QC', 'BC'];
  calculatedProvinces2021 = ['ON', 'AB', 'QC', 'BC'];
  calculatedProvinces2022 = ['ON', 'AB', 'QC', 'BC'];
  calculatedProvinces2023 = ['ON', 'AB', 'QC', 'BC'];
  private summaries: any;

  constructor(private questionsStore: QuestionStoreService) { }

  setSummaries(summaries: any): void {
    this.summaries = summaries;
  }

  getFederalTaxAndStatus(): { value: number, status: TaxStatus } {
    let tax;

    if (this.summaries.suM_F9 === 0 && this.summaries.suM_F11 === 0) {
      tax = { value: this.summaries.suM_F10, status: TaxStatus.Balance };

      return tax;
    }

    tax = { value: this.summaries.suM_F9, status: TaxStatus.Refund };

    if (this.summaries.suM_F10 !== 0 && this.summaries.suM_F10 !== null) {
      tax = { value: this.summaries.suM_F10, status: TaxStatus.Balance };
    } else if (this.summaries.suM_F11 !== 0 && this.summaries.suM_F11 !== null) {
      tax = { value: this.summaries.suM_F11, status: TaxStatus.Owe };
    }

    return tax;
  }

  getQuebecTaxAndStatus(): { value: number, status: TaxStatus } {
    let tax = { value: this.summaries.quebecSummary.suM_QC7, status: TaxStatus.Refund };

    if (this.summaries.quebecSummary.suM_QC8 !== null) {
      tax = { value: this.summaries.quebecSummary.suM_QC8, status: TaxStatus.Balance };
    } else if (this.summaries.quebecSummary.suM_QC9 !== null) {
      tax = { value: this.summaries.quebecSummary.suM_QC9, status: TaxStatus.Owe };
    }

    return tax;
  }

  /**
   * Check is Quebec Province
   */
  hasQuebecTax(): boolean {

    return this.questionsStore.getAnswerByQuestionId('FR_Q18_1').value === 'QC';
  }

  //TODO: remove when all provinces calculated
  isCalculatedProvince(q18Answer): boolean {

    return this.calculatedProvinces.includes(q18Answer)
  }

  //TODO: remove when all provinces calculated
  isCalculatedProvince2020(q18Answer): boolean {

    return this.calculatedProvinces2020.includes(q18Answer)
  }

   //TODO: remove when all provinces calculated
   isCalculatedProvince2021(q18Answer): boolean {

    return this.calculatedProvinces2021.includes(q18Answer)
  }

  //TODO: remove when all provinces calculated
  isCalculatedProvince2022(q18Answer): boolean {

    return this.calculatedProvinces2022.includes(q18Answer)
  }

  //TODO: remove when all provinces calculated
  isCalculatedProvince2023(q18Answer): boolean {

    return this.calculatedProvinces2023.includes(q18Answer)
  }
}
