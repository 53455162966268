<h1>Forgotten password</h1>
<div *ngIf="accountService.hasError" class="form-group text-danger">
  {{accountService.errorMessage}}
</div>
<div *ngIf="accountService.successfullMessage" class="alert-success mb-2 response-message p-2">
  {{accountService.successfullMessage}}
</div>
<form novalidate [formGroup]="formValidationService.formGroup">
  <div class="md-form">
    <input mdbInput type="email" formControlName="email" class="form-control">
    <label for="email">{{ 'MyProfile.EmailLabel' | translate }}</label>
    <mdb-error class="form-field-error" *ngIf="formValidationService.controlHasError('email', 'required')">
      Email is required
    </mdb-error>
    <mdb-error class="form-field-error" *ngIf="formValidationService.controlHasError('email', 'pattern')">
      Please enter a valid email
    </mdb-error>
  </div>
  <div class="form-element row">
    <div class="col text-center">
      <button class="btn btn-primary btn-block my-4" (click)="onResetPasswordButtonClicked()">Reset my password</button>
    </div>
  </div>
</form>
