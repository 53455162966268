export enum ResponseCode {
  // Finished Events
  PersonalDetailsFinished = 211,
  StateTaxesFinished = 214,
  NonResident = 219,
  StateFinished = 230,
  MessageForResidencyFinishWithNonResident = 235,
  MessageForResidencyFinishWithEmigrant = 236,
  IncomeRecoveriesFinished = 238,
  ExpensesTypesFinished = 239,

  // Finished with offline message
  Resident = 209,
  NotSupportedVisa = 216,
  ResidentTurboTax = 217,
  ResidencyOfflineStateEvent = 220,
  OfflineStateEvent = 221,
  Option2OfflineStateEvent = 228,
  Deceased = 222,
  M51Emigrant = 224,
  M12 = 225,
  M11 = 227,
  NoUsIncomeStatusCode = 231,
  DifferentServiceTypeStatusCode = 232,
  MessageForResidencyFinishWithResident = 234,
  MessageForResidencyFinishWithImmigrant = 237,
  FhsaOfflineStateEvent = 240,

  // Initialize
  StateInitialization = 212,
  AnswerQuestions = 213,
  
  // Skip
  Skip = 223
}
